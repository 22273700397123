import React, { useEffect, useRef, useState } from "react";

import {
  CircularProgress,
  CircularProgressProps,
  SxProps,
} from "@mui/material";

export const LoadingElement = ({
  top = "calc(50% - 40px)",
  left = "calc(50% - 40px)",
  position = "absolute",
  label,
  ...props
}: CircularProgressProps & {
  left?: string;
  top?: string;
  position?: string;
  label?: string;
}) => {
  const spanRef = useRef<HTMLSpanElement>(null);
  const [spanWidth, setSpanWidth] = useState(0);
  useEffect(() => {
    if (spanRef.current) {
      setSpanWidth(spanRef.current.offsetWidth);
    }
  }, [label]);

  return (
    <div>
      <CircularProgress
        {...props}
        sx={{ ...props.sx, position, top, left } as SxProps}
      />
      {label ? (
        <span
          ref={spanRef}
          style={{
            position: "absolute",
            top: "calc(50% + 1rem)",
            left: `calc(50% - ${spanWidth / 1.5}px)`, // Centers the label dynamically
          }}
        >
          {label}
        </span>
      ) : null}
    </div>
  );
};
