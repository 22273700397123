import React, { Suspense } from "react";

import { InteractionType } from "@azure/msal-browser";
import { MsalAuthenticationTemplate, MsalProvider } from "@azure/msal-react";
import { createRoot } from "react-dom/client";

import { msalInstance } from "./auth/authHandler";
import { LoadingElement } from "./components/Elements";

import { AuthLoader } from "./AuthLoader";
import "./sentry";

const root = createRoot(document.getElementById("root")!);

msalInstance.initialize().then(() => {
  root.render(
    <MsalProvider instance={msalInstance}>
      <MsalAuthenticationTemplate interactionType={InteractionType.Redirect}>
        <Suspense fallback={<LoadingElement label="Loading msalInstance" />}>
          <AuthLoader />
        </Suspense>
      </MsalAuthenticationTemplate>
    </MsalProvider>,
  );
});
